/* eslint-disable prettier/prettier */
import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Helmet from "react-helmet";

const StyledBreadCrumb = styled.div`
  display: flex;
  margin: 1rem 0rem 0rem 0rem;
  font-size: 14px;
  color: var(--foregrounddark);

  .breadcrumblevel > span:hover {
    text-decoration: underline;
  }

  .breadcrumblevel:after {
    content: "»";
    margin: 0rem 1vmin;
  }

  .breadcrumblevel:last-child:after {
    content: "";
  }
`;

const generateBreadCrumbSchema = breadCrumbObj => {
  const data = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": []
  };

  Object.keys(breadCrumbObj).map((level, i) => {
    data.itemListElement.push({
      "@type": "ListItem",
      "position": i + 1,
      "name": level === "Home" ? "Bluerivermountains" : level,
      "item": `https://bluerivermountains.com${breadCrumbObj[level]}`
    });
  });

  return JSON.stringify(data);
};

const Breadcrumb = ({ breadCrumbLevels }) => (
  <StyledBreadCrumb className="breadcrumb">
    <Helmet>
      <script type="application/ld+json">{generateBreadCrumbSchema(breadCrumbLevels)}</script>
    </Helmet>


    {Object.keys(breadCrumbLevels).map(level => {
      // START make the last breadcrumb not clickable
      if (level == Object.keys(breadCrumbLevels)[Object.keys(breadCrumbLevels).length - 1]) {
        return <span key={level}>{level}</span>;
      }
      // END
      return (
        <Link to={breadCrumbLevels[level]} className="breadcrumblevel" key={level}>
          <span key={level}>{level}</span>
        </Link>
      );
    })}
  </StyledBreadCrumb>
);

export default React.memo(Breadcrumb);
