import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

import { Link } from "gatsby";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  "AGBs": "de/allgemeine-geschaeftsbedingungen"

};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "de",
    href: "/de/allgemeine-geschaeftsbedingungen"
  },
  {
    hreflang: "en",
    href: "/en/legal-notice"
  },
  {
    hreflang: "x-default",
    href: "/en/legal-notice"
  }
];

const AGBs = props => {
  return (

    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Allgemeine Geschäftsbedingungen"
          description="Allgemeine Geschäftsbedingungen zu Leistungen aus dem Bereich des Online-Marketings an, so u.a. Suchmaschinenoptimierung, Web Analytics Implementierungen und Beratung zu Online-Marketing."
          lang="de"
          alternateLangs={alternateLangs}
        />
        <MainContent
          article={false}

        >
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />


          <H as="h1">Allgemeine Geschäftsbedingungen</H>

          <p>Matthias Kupperschmidt bietet diverse Leistungen (im Folgenden „Leistungen“) aus dem Bereich des Online-Marketings an, so u.a. Suchmaschinenoptimierung, Web Analytics Implementierungen, Beratung zu Online-Marketing Themen sowie Schulungen und Workshops.</p>

          <p>Die Nutzung der bereitgestellten Leistungen ist nur zu dem dafür vorgesehenen und in diesen allgemeinen Bedingungen bzw. im Auftrag beschriebenen Zweck zulässig. Eine Nutzung oder Verarbeitung für andere Zwecke ist im Übrigen nur unter den engen Voraussetzungen der deutschen Datenschutzgesetze, insbesondere des Bundesdatenschutzgesetzes, zulässig. Jede zweckfremde Nutzung oder Verwertung ist unzulässig. So ist insbesondere die vollständige Verwendung der bereitgestellten Leistungen und Inhalte für gewerbliche Zwecke jeglicher Art und in jeder medialen Form (in Printform, elektronisch, auf CD etc.) sowie das Auslesen von Verzeichnissen, Datenbanken etc. zu vorgenannten Zwecken sowie zu Zwecken sonstiger kommerzieller Verwendung nicht gestattet und wird von dem Anbieter nach geltendem Recht unter Ausschöpfung des Rechtsweges verfolgt.</p>

          <p>Für die Verfügbarkeit der einzelnen Leistungen sowie für die Richtigkeit und Vollständigkeit der Inhalte/Einträge/Daten wird keine Gewähr übernommen.</p>
          <p>Diese Bedingungen regeln die Beziehungen zwischen Matthias Kupperschmidt und seinen Kunden und Vertragspartnern (im Folgenden „Vertragspartner“).</p>

          <p>§ 1 Geltungsbereich</p>
          <p>Die Leistungen von Matthias Kupperschmidt erfolgen ausschließlich auf Grund dieser Geschäftsbedingungen. Diese gelten somit auch für alle künftigen Geschäftsbeziehungen, auch wenn sie nicht nochmals ausdrücklich vereinbart werden.</p>

          <p>Für die Nutzung der von Matthias Kupperschmidt angebotenen Leistungen durch den Vertragspartner gelten ausschließlich diese Allgemeinen Geschäftsbedingungen (AGB) sowie die besonderen Geschäftsbedingungen für die jeweiligen Produkte/Services. Für darüberhinausgehende Angebote, Leistungen, Einzelprojekte, Aktionen sowie sonstige Leistungen, insbesondere Projektleistungen, gelten gesonderte einzelvertraglich zu vereinbarende Bedingungen.</p>

          <p>Allgemeine Geschäftsbedingungen von Vertragspartnern oder sonstiger Dritter finden auch dann keine Anwendung, wenn Matthias Kupperschmidt ihnen nicht ausdrücklich widerspricht und/oder seine Leistungen widerspruchslos erbringt.</p>

          <p>§ 2 Allgemeiner Leistungsumfang</p>
          <p>Matthias Kupperschmidt ist u.a. in den Bereichen Suchmaschinenoptimierung, Inbound Marketing, Web Analytics, Beratung zu Online-Marketing Themen sowie Schulungen und Workshops tätig. Eine detaillierte Beschreibung der jeweils beauftragten Leistung von Matthias Kupperschmidt ergibt sich aus dem jeweiligen Auftrag.</p>
          <p>Die von Matthias Kupperschmidt angebotenen Leistungen und Inhalte werden, soweit nicht gesondert gekennzeichnet, bereitgestellt und veröffentlicht. Solche Inhalte, die von Dritten zugänglich gemacht werden, sind Informationen des entsprechenden Urhebers, Autors oder Verbreiters und nicht solche von Matthias Kupperschmidt. Matthias Kupperschmidt ist deshalb für die Genauigkeit oder Verlässlichkeit dieser Inhalte nicht verantwortlich.</p>
          <p>Die von Matthias Kupperschmidt angebotenen Leistungen stellen lediglich eine Aufforderung zur Abgabe eines Angebots zum Vertragsschluss dar. Der Vertragspartner ist an seine Erklärung zehn Werktage gebunden. Zum Abschluss eines Vertrags bedarf es der Bestätigung in Textform (per E-Mail ausreichend) seitens Matthias Kupperschmidt. Sodann kommt ein Geschäftsvertrag auf der Grundlage der jeweils gültigen AGB zustande.</p>
          <p>Matthias Kupperschmidt ist zum Abschluss von Verträgen nicht verpflichtet und kann den Abschluss von Verträgen ohne Angabe von Gründen jederzeit ablehnen. Nachträgliche Änderungen und Ergänzungen der Verträge bedürfen der schriftlichen Bestätigung von Matthias Kupperschmidt. Für weitere Dienstleistungen werden zwischen den Vertragspartnern gesonderte Verträge vereinbart.</p>
          <p>Matthias Kupperschmidt behält sich ferner vor, Vertragspartner nachträglich von der Nutzung der Leistungen auszuschließen, wenn Anhaltspunkte dafür bekannt werden, dass der Vertragspartner Leistungen zweckentfremdet oder in einer Art und Weise nutzt, die geeignet ist, den guten Ruf von Matthias Kupperschmidt zu gefährden. Dies gilt insbesondere für den Fall, dass der Vertragspartner Pflichten aus dem bestehenden Vertragsverhältnis verletzt oder andere Vertragspartner von Matthias Kupperschmidt schädigt.</p>
          <p>Soweit ein Vertragspartner im Rahmen der Nutzung der Leistungen Software des anderen Vertragspartners nutzt, gewährt der jeweils andere Vertragspartner ihm, soweit nicht ausdrücklich anders geregelt, ein einfaches, nicht übertragbares, zeitlich auf die Vertragsdauer und inhaltlich auf die Nutzung im Sinne der Leistungserbringung beschränktes Nutzungsrecht zum eigenen Gebrauch. Weitergehende Nutzungsrechte werden dem Vertragspartner nicht gewährt.</p>
          <p>Matthias Kupperschmidt ist berechtigt, zu Zwecken der Wartung und zur Durchführung von Reparaturen die Erbringung einzelner Leistungen oder Teile davon in für den Vertragspartner zumutbarem Ausmaß zu unterbrechen. Matthias Kupperschmidt bemüht sich, Wartungs- und Reparaturarbeiten zu insgesamt möglichst gering belastenden Zeiten vorzunehmen. Dies begründet bei fehlendem Verschulden von Matthias Kupperschmidt keine Regress- oder Ersatzansprüche des Vertragspartners jedweder Art gegen Matthias Kupperschmidt.</p>
          <p>Matthias Kupperschmidt hat das Recht, auch mit Wettbewerbern des Vertragspartners entsprechende Verträge zu schließen. Matthias Kupperschmidt wird sich, ohne hierzu verpflichtet zu sein, jedoch gegebenenfalls bemühen, berechtigte Interessen des Vertragspartners zu wahren und Interessenskollisionen zu vermeiden. Matthias Kupperschmidt ist zu Teilleistungen und zu Leistungen durch Dritte berechtigt.</p>
          <p>Vom Vertragspartner gewünschte Fristen und Termine sind nur verbindlich, wenn Matthias Kupperschmidt diese ausdrücklich schriftlich bestätigt hat und der Vertragspartner rechtzeitig alle in seinem Einflussbereich liegenden Voraussetzungen zur Erbringung der Leistung getroffen hat.</p>
          <p>Soweit und solange der Vertragspartner irgendeine der zur Leistungserbringung erforderlichen Mitwirkungspflichten auch nach schriftlicher Aufforderung durch das Setzen einer angemessenen Nachfrist nicht erfüllt, ist Matthias Kupperschmidt von der Erfüllung seiner davon beeinflussten Verpflichtung zu der im Projektvertrag vereinbarten Frist befreit. Die diesbezüglich im Auftrag/Vertrag vereinbarte Frist wird angemessen verlängert.</p>

          <p>§ 3 Beauftragung, Vertragsumfang</p>
          <p>Jede Leistung ist durch den Vertragspartner gesondert zu beauftragen (Einzelauftrag/Leistungsschein). Im Auftrag werden der Name der Aktion, Leistungsdatum und –zeitraum, die Inhalte der Leistung sowie das für Matthias Kupperschmidt anfallende Honorar bzw. die Provision genau beziffert. Die Vertragsparteien liefern sich jeweils gegenseitig die zur Auftragserfüllung notwendigen Inhalte. Sofern in diesen Inhalten Tracking-Codes eingebunden sind, dürfen diese nur nach vorheriger Absprache vom anderen Vertragspartner verändert oder gelöscht werden.</p>
          <p>Matthias Kupperschmidt wird dem Vertragspartner für jede erfolgte Aktion eine Rechnung erstellen, aus der die unter Ziffer 1 aufgeführten Angaben eindeutig hervorgehen.</p>
          <p>§ 4 Pflichten des Vertragspartners</p>
          <p>Der Auftraggeber verpflichtet sich zur Unterstützung der von Matthias Kupperschmidt geleisteten Maßnahmen. Der Auftraggeber liefert Matthias Kupperschmidt alle Informationen und Daten, die zur Auftragserfüllung erforderlich sind.</p>
          <p>Der Vertragspartner verpflichtet sich, dass sämtliche von ihm gelieferte Inhalte, welche im Zusammenhang mit der Nutzung der Leistungen von Matthias Kupperschmidt stehen, nicht gegen geltendes Recht der Bundesrepublik Deutschland verstoßen, insbesondere keine rechtswidrigen, sittenwidrigen oder pornografischen Inhalte oder solche Inhalte enthalten, die den Krieg verherrlichen oder geeignet sind, Kinder oder Jugendliche sittlich schwer zu gefährden oder in ihrem Wohle zu beeinträchtigen.</p>
          <p>Darüber hinaus zeigt der Vertragspartner alle Vorgänge und Umstände an, die für die ordnungsgemäße und zeitgerechte Ausführung des Auftrags von Bedeutung sein könnten. Dies gilt auch für Vorgänge und Umstände, die dem Vertragspartner erst während der Vertragslaufzeit bekannt werden. Projektverzögerungen aufgrund des Umstandes, dass der Vertragspartner notwendige Mitwirkungshandlungen nicht oder nicht rechtzeitig erfüllt, gehen ausschließlich zu Lasten des Vertragspartners und berühren den Vergütungsanspruch von Matthias Kupperschmidt nicht.</p>
          <p>Jeglicher Verstoß gegen die unter Ziffer 1 aufgeführte Verpflichtung berechtigt zur sofortigen Kündigung des zwischen Matthias Kupperschmidt und dem Vertragspartner bestehenden Vertrages durch Matthias Kupperschmidt. Matthias Kupperschmidt behält sich das Recht zur sofortigen Kündigung vor, wenn bei dem Vertragspartner bereits der Verdacht auf die Verbreitung oder Duldung von in Ziffer 1 aufgeführten Inhalten besteht. Matthias Kupperschmidt ist zur Kontrolle der Rechtmäßigkeit der Inhalte berechtigt, aber nicht verpflichtet.</p>
          <p>Der Vertragspartner verpflichtet sich, von Matthias Kupperschmidt geforderte technische und inhaltliche Spezifikationen strikt einzuhalten. Etwaige aus der Nichteinhaltung dieser Spezifikationen folgende Verzögerungen und/oder Änderungen der von Matthias Kupperschmidt erbrachten Leistungen gehen zu Lasten des Vertragspartners und begründen keine Ansprüche gegen Matthias Kupperschmidt.</p>
          <p>Der Vertragspartner ist zudem dafür verantwortlich, dass alle Informationen und Unterlagen, die zur Erfüllung seiner vertraglichen Verpflichtungen und zur ordnungsgemäßen Durchführung der Zusammenarbeit erforderlich sind, Matthias Kupperschmidt in einer zur Erfüllung geeigneten Form rechtzeitig und vollständig vor der vereinbarten Auftragsumsetzung zur Verfügung stehen.</p>
          <p>Soweit eine Partei durch Umstände, die sie nicht zu vertreten hat, an der Bereitstellung der vertraglich vorgesehenen Leistung und/oder Inhalte gehindert wird, informiert sie die jeweils andere Partei entsprechend und übermittelt unverzüglich den Umständen angepasste Inhalte und/oder Leistungen. Sofern die vorgenannten Umstände mehr als 24 Stunden andauern, verständigen sich die Parteien einvernehmlich über das weitere Vorgehen.</p>
          <p>Der Vertragspartner ist nicht berechtigt, die Marken und/oder Logos von Matthias Kupperschmidt oder ihren Kunden und Partnern zu verwenden, wiederzugeben, zu verbreiten, zu veröffentlichen, zu ändern oder in sonstiger Weise zu nutzen, es sei denn, Matthias Kupperschmidt hat vorher ausdrücklich schriftlich zugestimmt. Ferner ist der Vertragspartner verpflichtet, fremde in- und ausländische Urheber- oder Markenrechte zu beachten.</p>
          <p>Der Vertragspartner stimmt der Weitergabe seiner Inhalte, Daten und sonstigen Angaben an Dritte insoweit zu, als dies zur Erfüllung der vertraglichen Beziehungen zwischen den Parteien oder zur Auftragsabwicklung notwendig und erforderlich ist.</p>

          <p>§ 5 Vertragslaufzeit / Kündigung</p>
          <p>Kostenvoranschläge von Matthias Kupperschmidt sind unverbindlich. Matthias Kupperschmidt verpflichtet sich, dem Vertragspartner Abweichungen der tatsächlichen Kosten von mehr als zehn Prozent gegenüber dem veranschlagten Wert unverzüglich mitzuteilen.</p>
          <p>Der zwischen Matthias Kupperschmidt und dem Vertragspartner geschlossene Vertrag endet mit Ablauf der vereinbarten Laufzeit. Im Übrigen ergibt sich die jeweilige Vertragslaufzeit aus dem Angebot.</p>
          <p>Wurde mit dem Vertragspartner eine Mindestlaufzeit vereinbart, so verlängert sich diese Vereinbarung automatisch um die vereinbarte Laufzeit, wenn nicht spätestens 30 Tage vor Ablauf der jeweiligen Vertragslaufzeit der Verlängerung schriftlich widersprochen wird.</p>
          <p>Verträge, die auf unbestimmte Zeit geschlossen worden sind, können von beiden Seiten jederzeit schriftlich mit einer Frist von drei Monaten zum Monatsende gekündigt werden.</p>
          <p>Das Recht zur Kündigung des Vertrages aus wichtigem Grunde bleibt unberührt. Ein wichtiger Grund liegt insbesondere dann vor, wenn eine der Parteien wesentliche Verpflichtungen aus diesem Vertrag oder geltendes Recht des Staates Dänemark verletzt und trotz schriftlicher Mahnung durch die andere Partei den vertragsgemäßen Zustand nicht innerhalb von 7 Tagen nach Mahnung wiederherstellt. Die Mahnung hat den Pflichtverstoß konkret zu bezeichnen und auf die Möglichkeiten der Kündigung aus wichtigem Grund zu verweisen. Wesentliche Vertragspflichten sind insbesondere die Geheimhaltungspflichten.</p>
          <p>Für Matthias Kupperschmidt ist ein wichtiger Grund gegeben bei</p>
          <p>Beantragung der Eröffnung des Insolvenz- oder Konkursverfahrens über das Vermögen des Vertragspartners sowie</p>
          <p>b. jeder sonstigen schuldhaften Verletzung vertragswesentlicher Pflichten eines Vertragspartners, sofern sie trotz Abmahnung nicht unverzüglich ausgeräumt wurde.</p>
          <p>Bis zur Wirksamkeit der Kündigung behält jeder Vertragspartner seinen Anspruch auf sämtliche noch anfallende Vergütungen und Provisionen. Bis zu diesem Zeitpunkt sind die Vertragspartner zudem zur Erfüllung ihrer vertraglichen Pflichten verpflichtet. Gegebenenfalls über die Vertragslaufzeit hinausgehende Verpflichtungen, insbesondere bezüglich des Datenschutzes und der Geheimhaltung, bleiben davon unberührt.</p>

          <p>Sämtliche Kündigungen können schriftlich oder per E-Mail erfolgen.</p>
          <p>§ 6 Vergütung und Fälligkeit</p>
          <p>Am Ende der Beratung bzw. Umsetzung von Maßnahmen wird die vereinbarte Vergütung mit Rechnungsstellung fällig. Es bedarf keiner Abnahme.</p>
          <p>Matthias Kupperschmidt steht bereits vor Abschluss der Beratung und Umsetzung der Maßnahmen eine angemessene Zahlung zu. Im Zweifel richtet sich die Angemessenheit nach der bereits geleisteten Arbeitszeit. Im Fall der dauerhaften Betreuung kann Matthias Kupperschmidt Leistungen unmittelbar nach ihrer Erbringung in Rechnung stellen. Die Honorierung richtet sich nach dem von Matthias Kupperschmidt gemachten Angebot.</p>

          <p>§ 7 Geldverkehr, Zahlungsbedingungen</p>

          <p>Die Vergütung bzw. Umsatzverteilung erfolgt grundsätzlich aufgrund der im Einzelauftrag bezeichneten Aufteilung.</p>
          <p>Die Abrechnung erfolgt – sofern nicht im Einzelauftrag gesondert vereinbart – monatlich durch Matthias Kupperschmidt. Sämtliche Umsätze und Vergütungen verstehen sich netto, die Umsatzsteuer wird gesondert ausgewiesen.</p>
          <p>Matthias Kupperschmidt ist berechtigt, Abschlagszahlungen von bis zu 100 % des Auftragsvolumens zu verlangen, die bei Vertragsunterzeichnung fällig sind.</p>
          <p>Bei Zahlungsverzug ist Matthias Kupperschmidt berechtigt, dem Vertragspartner – unter Vorbehalt der Geltendmachung eines weiteren Schadens – Verzugszinsen in Höhe von fünf Prozentpunkten über dem jeweiligen Basiszins zu berechnen. Die Verzugszinsen sind niedriger anzusetzen, wenn der Vertragspartner eine geringere Belastung nachweist.</p>
          <p>Dem Vertragspartner steht ein Zurückbehaltungsrecht oder das Recht zur Aufrechnung nur insoweit zu, als die Forderungen des Vertragspartners unbestritten oder rechtskräftig sind.</p>

          <p>§ 8 Eigentumsrechte und Urheberschutz, Nutzungsrechte</p>
          <p>Die Urheberrechte und sonstigen Schutzrechte an den von Matthias Kupperschmidt erbrachten Leistungen verbleiben bei Matthias Kupperschmidt.</p>
          <p>Matthias Kupperschmidt überträgt dem Vertragspartner einfache, zeitlich und räumlich unbegrenzte Nutzungsrechte für die Verwertung der vollständigen und als vertragsgemäß abgelieferten Leistung (Arbeitsergebnisse).</p>
          <p>Der Vertragspartner ist nicht berechtigt, die gelieferten Inhalte und Informationen im Übrigen an Dritte weiter zu veräußern oder außerhalb ihrer Zweckbestimmung anderweitig zur Verfügung zu stellen.</p>
          <p>Änderungen der von Matthias Kupperschmidt erbrachten Leistungen durch den Vertragspartner oder einen vom Vertragspartner beauftragten Dritten sind nur mit ausdrücklicher Zustimmung von Matthias Kupperschmidt zulässig.</p>
          <p>Die Ausübung der Nutzungsrechte ist nur gestattet, wenn sich der Vertragspartner zu diesem Zeitpunkt nicht mit einer Zahlung in Verzug befindet.</p>
          <p>Matthias Kupperschmidt stellt durch Vereinbarung mit Mitarbeitern, Erfüllungsgehilfen und etwaigen dritten Rechteinhabern sicher, dass der Vertragspartner die vertragsgemäßen Nutzungsrechte ungehindert ausüben kann.</p>

          <p>§ 9 Gewährleistung und Haftung</p>
          <p>Matthias Kupperschmidt wird seine Leistungen im Rahmen seiner technischen Möglichkeiten betreiben und übernimmt weder Gewähr oder Garantie für eine ununterbrochene und/oder störungsfreie ständige Verfügbarkeit seiner Leistungen noch insbesondere für Leitungs- und/oder Anbindungsausfälle, Hard- und/oder Softwarefehler sowie Einwirkungen Dritter (z.B. Viren oder „denial of services attacks“). Die Vertragsparteien sind sich darüber einig, dass das Auftreten solcher Unterbrechungen und/oder Störungen keine Regress- oder Ersatzansprüche jeglicher Art begründet.</p>
          <p>Mängel sind Abweichungen von der Leistungsbeschreibung, welche die Nutzung für den vertragsgemäßen Gebrauch einschränken. Auftretende Mängel müssen morefire detailliert mitgeteilt werden. Die Parteien wirken bei Mängelanalyse und -behebung zusammen. Die Nacherfüllung erfolgt im Rahmen der Mängelhaftung ohne Kosten für den Vertragspartner.</p>
          <p>Der Vertragspartner räumt morefire eine angemessene Frist für die Nacherfüllung ein. Gelingt diese auch innerhalb einer weiteren, angemessenen Nachfrist nicht (jeweils mindestens 15 Werktage), kann der Vertragspartner, wenn er dies bei der Nachfristsetzung angekündigt hat, Herabsetzung der Vergütung (Minderung) oder Schadensersatz wegen Nichterfüllung verlangen.</p>
          <p>Mängelansprüche des Vertragspartners können nicht mehr geltend gemacht werden, wenn er selbst ohne vorherige schriftliche Zustimmung Änderungen an Leistungen durchgeführt hat oder durch Dritte hat durchführen lassen, es sei denn, der Vertragspartner weist nach, dass die Mängel nicht auf diese Änderungen zurückzuführen sind oder diese Änderungen die Mängelbeseitigung, insbesondere die Analyse- und Beseitigungsarbeiten nicht oder nur unwesentlich erschweren.</p>
          <p>Mängelansprüche bestehen grundsätzlich nicht, wenn der Vertragspartner die Leistungen unter gegenüber der Vereinbarung abweichenden Einsatzbedingungen nutzt. Dabei obliegt dem Vertragspartner der Nachweis, dass der Mangel nicht durch ein solches Abweichen verursacht wurde.</p>
          <p>Die Ansprüche nach Nr. 266 LBK auf Schadensersatz wegen nicht von Matthias Kupperschmidt zu vertretender Mangelhaftigkeit zu Vertragsbeginn sowie lediglich fahrlässige Mangelhaftigkeit nach Vertragsbeginn sind ausgeschlossen.</p>
          <p>Mängelansprüche verjähren ein Jahr nach Abnahme, bei nicht abnahmefähigen oder –bedürftigen Leistungen ein Jahr nach Ablieferung / Übernahme oder Anzeige der Fertigstellung bzw. Leistungserbringung und/oder -bereitstellung.</p>
          <p>Matthias Kupperschmidt haftet nicht für technische Störungen, deren Ursachen nicht im Verantwortungsbereich von Matthias Kupperschmidt liegen sowie für Schäden, die durch höhere Gewalt verursacht werden. Es kann in Ausnahmefällen dazu kommen, dass einzelne Daten, Leistungen oder sonstige technische oder tatsächliche Vorgänge von Matthias Kupperschmidt nicht erfasst oder korrekt verrechnet werden können. Ein darauf beruhender Anspruch – insbesondere ein Schadens- oder Wertersatzanspruch – gegenüber Matthias Kupperschmidt seitens des Vertragspartners ist ausgeschlossen.</p>
          <p>Matthias Kupperschmidt haftet lediglich für Schäden aufgrund der Verletzung wesentlicher vertraglicher Hauptpflichten (Kardinalpflichten) oder aufgrund des Fehlens zugesicherter Eigenschaften. Darüber hinaus haftet Matthias Kupperschmidt nur, wenn der Schaden grob fahrlässig oder vorsätzlich verursacht wurde.</p>
          <p>Matthias Kupperschmidt haftet im Fall einer leicht fahrlässigen Verletzung einer wesentlichen vertraglichen Hauptpflicht und außervertraglich dem Grunde nach nur für die Verletzung von Leben, Körper und Gesundheit, Garantien, vertragswesentlichen Pflichten sowie nach dem Produkthaftungsgesetz, der Höhe nach nur für den typischen und bei Vertragsschluss vorhersehbaren Schaden. Dieser bemisst sich anhand des durchschnittlichen monatlichen Auftragsvolumens innerhalb der letzten sechs Monate. Insbesondere ist eine Haftung bei leicht fahrlässiger Verletzung vertragswesentlicher Pflichten für entgangenen Gewinn, indirekte Schäden und Mangelfolgeschäden grundsätzlich ausgeschlossen.</p>
          <p>Eine Haftung für Folgeschäden, mittelbare Schäden und/oder entgangenen Gewinn ist bei anderem als vorsätzlichem Verhalten ausgeschlossen. Die Haftung beschränkt sich in der Höhe auf den bei einem Schadenseintritt im Inland zu erwartenden gewöhnlichen Schaden, höchstens aber auf die nach dem jeweiligen Auftrag von dem Vertragspartner zu entrichtende Gesamtvergütung. Diese Haftungsbeschränkung gilt in gleicher Weise für Schäden, die aufgrund von grober Fahrlässigkeit oder Vorsatz von Mitarbeitern oder Beauftragten von Matthias Kupperschmidt verursacht werden.</p>
          <p>Hat der Vertragspartner ohne Einwilligung von Matthias Kupperschmidt Änderungen an der vertragsgegenständlichen Leistung oder deren Umgebung vorgenommen oder vornehmen lassen, so erlöschen alle Ansprüche des Vertragspartners aus vorstehenden Bestimmungen, es sei denn der Mangel beruht weder ganz noch teilweise auf den von dem Vertragspartner vorgenommen Änderungen und die Fehlerbeseitigung wird durch die Änderungen nicht erschwert.</p>
          <p>Diese Haftungsbeschränkungen gelten sinngemäß auch zugunsten der Mitarbeiter und Beauftragten von Matthias Kupperschmidt . Eine eventuelle Haftung von Matthias Kupperschmidt aufgrund des Produkthaftungsgesetzes bleibt unberührt.</p>
          <p>Alle Schadensersatz- oder sonstigen Ansprüche, die wegen der Beschaffenheit oder Mängeln der Leistung gegen Matthias Kupperschmidt geltend gemacht werden könnten, verjähren 6 (in Worten: sechs) Monate nach Beendigung des jeweiligen Vertrages.</p>
          <p>Der Vertragspartner haftet gegenüber Matthias Kupperschmidt für die von ihm zur Verfügung gestellten Inhalte, Dokumente, Links und sonstige Materialien – im Folgenden „Unterlagen“ – die er Matthias Kupperschmidt zur Abwicklung der Zusammenarbeit zur Verfügung stellt.</p>
          <p>Der Vertragspartner überprüft die Übereinstimmung der von Matthias Kupperschmidt erbrachten Leistungen mit den einschlägigen rechtlichen Vorschriften im Bedarfsfall auf eigene Rechnung. Matthias Kupperschmidt veranlasst eine derartige externe rechtliche Prüfung nur auf ausdrücklichen schriftlichen Wunsch des Vertragspartners; die damit verbundenen Kosten trägt der Vertragspartner.</p>
          <p>Machen Dritte Ansprüche gegen Matthias Kupperschmidt wegen einer im Pflichtenkreis des Vertragspartners aufgetretenen Rechtsverletzung und/oder einer Verletzung geltenden Rechts geltend, hat der Vertragspartner alles in seiner Macht Stehende zu tun, um Matthias Kupperschmidt auf seine Kosten gegen die geltend gemachten Ansprüche bzw. Rechte zu verteidigen und die behauptete Rechtsverletzung zu beseitigen. Der Vertragspartner haftet gegenüber Matthias Kupperschmidt ferner für alle durch Dritte verschuldeten Schäden, die infolge eines nur teilweise, fehlerhaft, verzögert, verspätet oder gar nicht ausgeführten Auftrags entstehen. Gleiches gilt auch für Erfüllungsgehilfen des Vertragspartners. Der Vertragspartner trägt die Beweislast für die ordnungsgemäße Erfüllung des Auftrages.</p>
          <p>Der Vertragspartner stellt Matthias Kupperschmidt von sämtlichen Schadensersatzansprüchen, Haftungsansprüchen und Kosten frei, die Matthias Kupperschmidt dadurch entstehen, dass ein Anspruch gegen sie geltend gemacht wird, dementsprechend die Unterlagen des Vertragspartners gegen das Wettbewerbsrecht, gewerbliche Schutzrechte Dritter oder andere Gesetze beziehungsweise Verordnungen verstoßen. Der Vertragspartner stellt Matthias Kupperschmidt insbesondere von etwaigen Ansprüchen Dritter, die aufgrund der verwendeten Inhalte oder eines über einen Hyperlink verbundenen Angebots von Matthias Kupperschmidt, unverzüglich auf erstes Anfordern frei und erstattet auf Anforderung die Matthias Kupperschmidt entstehenden Kosten einer zweckentsprechenden Rechtsverfolgung.</p>

          <p>§ 10 Zulässigkeit von Internetseiten</p>
          <p>Matthias Kupperschmidt überprüft nicht, ob die Inhalte der Internetseiten des Auftraggebers Rechte Dritter verletzen oder gemäß den Richtlinien der einzelnen Suchmaschinenbetreiber aufgebaut sind. Für eine mögliche Abstrafung durch Dritte ist allein der Auftraggeber verantwortlich.</p>
          <p>Der Auftraggeber ist ebenfalls für die rechtliche Zulässigkeit der Inhalte seiner Internetseiten sowie für die von ihm gelieferten Informationen einschließlich der Suchbegriffe bzw. Keywords allein verantwortlich, gleiches gilt für den Schutz der Rechte Dritter, insbesondere in urheberechtlicher, wettbewerbsrechtlicher und strafrechtlicher Hinsicht.</p>

          <p>§11 Dienstverträge</p>
          <p>Im Rahmen von Dienstverträgen eingesetzte Mitarbeiter dürfen vom Auftraggeber binnen einer Frist von 24 Monaten nach Auftragserteilung nicht abgeworben werden. Im Falle einer Abwerbung innerhalb dieser Frist wird eine einmalige Entschädigung in Höhe von € 75.000 zugunsten des Auftragnehmers fällig.</p>

          <p>§ 12 Datenschutz</p>
          <p>Personenbezogene Daten werden – soweit nichts anderes bestimmt – ohne weitere zusätzliche Einwilligung nur zum Zwecke der Bereitstellung der Leistungen, der Nutzung und gegebenenfalls der Abrechnung erhoben, verarbeitet und genutzt.</p>
          <p>Der Vertragspartner verpflichtet sich zur Einhaltung der geltenden Bestimmungen des Datenschutzes, insbesondere der Bestimmungen des dänischen Datenschutzgesetzes (Databeskyttelseslov) und der Datenschutz-Grundverordnung (DSGVO) der EU.</p>
          <p>Der Vertragspartner sichert zu, eventuell bei sich erhobene Daten ausschließlich zum jeweils angegebenen Zweck sowie zu Marktforschungs- und Werbezwecken innerhalb des Unternehmens zu verwenden, soweit dies für die Durchführung der abgeschlossenen Geschäfte und die Pflege der daraus resultierenden Nutzerbeziehung erforderlich, gesetzlich zulässig und von dem Nutzer gewünscht ist. Ein Weiterverkauf von Daten an Dritte durch den Vertragspartner ist ausgeschlossen und wird angezeigt.</p>

          <p>§ 13 Geheimhaltung und Verschwiegenheit</p>
          <p>Der Vertragspartner verpflichtet sich, die von Matthias Kupperschmidt erhaltenen Unterlagen, Kenntnisse und Erfahrungen ausschließlich für die Zwecke dieses Vertrages zu verwenden. Darüber hinaus vereinbaren die Vertragsparteien Vertraulichkeit über den Inhalt zwischen ihnen getroffener Vereinbarungen und über die bei deren Durchführung und Abwicklung gewonnenen Erkenntnisse, auch gegenüber Dritten. Der Vertragspartner ist verpflichtet, mit Matthias Kupperschmidt Rücksprache zu halten, wenn irgendwelche Zweifel aufkommen sollten, ob eine Information im konkreten Einzelfall als vertraulich zu behandeln ist.</p>
          <p>Bei einer eventuellen Unterauftragsvergabe wird der Vertragspartner dem jeweiligen Unterauftragnehmer dieser Bestimmung vergleichbare Verpflichtungen auferlegen.</p>
          <p>Der Vertragspartner hat gegenüber seinen Mitarbeitern und an der Vertragsdurchführung beteiligten Dritten, die Zugang zu den in Abs. 1 genannten Unterlagen und Gegenständen haben, die Einhaltung dieser Geheimhaltungsverpflichtung sicherzustellen.</p>

          <p>§ 14 Änderungen</p>
          <p>Matthias Kupperschmidt erbringt seine Leistungen entsprechend der jeweiligen Produktbeschreibungen. Matthias Kupperschmidt behält sich vor, die Leistungen insgesamt oder die im Rahmen der Bereitstellung der Leistungen angebotenen Leistungen sowie Art und Inhalt der einzelnen Produkte und Leistungen jederzeit und wiederholt zur Verbesserung des Angebots, insbesondere seiner Funktionen, seines Designs und seiner technischen Weiterentwicklung ganz oder teilweise zu ändern, erweitern oder anzupassen. Über wesentliche Änderungen der Produkte wird der Vertragspartner rechtzeitig per E-Mail ausdrücklich informiert. Dabei werden die Produktbeschreibungen in der neuen Fassung beigefügt. Wenn der Vertragspartner mit der Änderung nicht einverstanden ist, kann er den Vertrag mit Matthias Kupperschmidt mit sofortiger Wirkung kündigen. Widerspricht der Vertragspartner nicht binnen zwei Wochen, so gelten die mitgeteilten Änderungen als neue Bedingungen.</p>
          <p>Matthias Kupperschmidt ist darüber hinaus berechtigt, einzelne im Rahmen der Leistungen angebotene Funktionen – insbesondere bei geänderten gesetzlichen Erfordernissen, dauerhaften Verlusten, technischen Schwierigkeiten oder Missbräuchen durch Kunden, Vertragspartner oder Dritte – einzustellen.</p>
          <p>Matthias Kupperschmidt behält sich auch Preisänderungen ausdrücklich vor und wird den Vertragspartner hierüber mit angemessener Frist informieren.</p>
          <p>Matthias Kupperschmidt behält sich ferner vor, diese AGB zu ändern oder zu ergänzen, insbesondere wenn neue Leistungen eingeführt werden oder wenn gesetzliche Bestimmungen Änderungen erforderlich machen. Änderungen oder Ergänzungen der AGB wird Matthias Kupperschmidt dem Vertragspartner mitteilen. Ist er mit den Änderungen nicht einverstanden, so kann er innerhalb von zwei Wochen nach ihrer Bekanntgabe widersprechen.</p>
          <p>Im Falle eines Widerspruchs des Vertragspartners gegen Änderungen der Leistungen, dieser AGB oder der besonderen Bedingungen für die einzelnen Produkte, hat Matthias Kupperschmidt das Recht, unter Geltung dieser AGB geschlossene Verträge außerordentlich ohne Einhaltung einer Kündigungsfrist zu kündigen. Widerspricht der Vertragspartner nicht, gilt seine Zustimmung nach Ablauf der oben genannten Fristen als erteilt. Auf die Dauer der Frist und auf die Bedeutung ihres ergebnislosen Ablaufs wird Matthias Kupperschmidt bei der Ankündigung der Änderung der AGB ausdrücklich hinweisen.</p>

          <p>§ 15 Schlussbestimmungen</p>
          <p>Eine Abtretung vertraglicher Rechte und Pflichten durch den Vertragspartner an einen Dritten ist nur mit vorheriger Einwilligung von Matthias Kupperschmidt zulässig.<br />
            Ein Zurückbehaltungsrecht besteht für den Vertragspartner nur hinsichtlich unbestrittener oder rechtskräftig festgestellter Gegenansprüche.</p>
          <p>Weitere Nebenabreden bestehen nicht. Vereinbarungen zwischen den Parteien haben schriftlich zu erfolgen. Dies gilt auch für die Aufhebung des Schriftformerfordernisses.</p>
          <p>Der Vertragspartner wird Matthias Kupperschmidt rechtzeitig über das Bevorstehen eines Insolvenzverfahrens informieren zu dem Zweck, die Erfüllung der vertraglich vereinbarten Leistungen und Pflichten zu gewährleisten oder aber für eine einvernehmliche Auflösung des Vertrages zu sorgen.</p>
          <p>Sollten einzelne Bestimmungen der Allgemeinen Geschäftsbedingungen einschließlich dieser Regelungen ganz oder teilweise unwirksam sein oder eine Lücke enthalten, bleibt die Wirksamkeit der übrigen Bestimmungen oder Teile solcher Bestimmungen unberührt. Die Parteien sind in solch einem Fall verpflichtet, an der Schaffung neuer Bestimmungen mitzuwirken, durch die ein der unwirksamen oder lückenhaften Bestimmung wirtschaftlich möglichst nahestehendes Ergebnis unter der Wahrung der beiderseitigen Interessen rechtswirksam erzielt wird.</p>
          <p>Erfüllungsort ist für beide Parteien der Sitz von Matthias Kupperschmidt.</p>
          <p>Es gilt ausschließlich dasRecht des Staates Dänemarks unter Ausschluss des UN-Kaufrechts sowie von Rückverweisungen auf ausländisches Recht.</p>
          <p>Ist der Kunde Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen oder hat er keinen Sitz im Inland, ist der ausschließliche Gerichtsstand Kopenhagen. Matthias Kupperschmidt kann auch vor dem zuständigen Gericht am Sitz des Vertragspartners klagen.</p>

          <p>§ 13 Streitbeilegungsverfahren</p>
          <p>Die Europäische Kommission hat eine Plattform zur Online-Streitbeilegung eingerichtet. Diese erreichen Sie unter: http://ec.europa.eu/consumers/odr/.</p>
          <p>Verbraucher können die Plattform für die Beilegung ihrer Streitigkeiten nutzen. Matthias Kupperschmidt ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet, außer es besteht eine gesetzliche Pflicht zur Teilnahme.</p>


          <p>Kopenhagen, 01. Februar 2019</p>




        </MainContent>
      </React.Fragment>

    </Layout>
  );
};
export default AGBs;
